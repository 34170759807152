<template>
  <div class="header">
    <div class="container">
      <div class="logo">
        <router-link to="/"
        ><img alt="" src="../../assets/images/listen/logo.png"
        /></router-link>
      </div>
      <div class="head-right">
        <ul class="head-menu">
          <li>
            <a class="btn-free" href="">免费试听</a>
          </li>
          <li>
            <a class="menu-link" href="">
              <i class="iconfont icon-lingdang"></i>
              <span class="el-badge">2</span>
            </a>
          </li>
          <li class="line"></li>
          <li>
            <a class="menu-link" href="">
              <i class="iconfont icon-gwc"></i>
              <span class="el-dot-badge"></span>
            </a>
          </li>
          <li class="line"></li>
          <li>
            <a class="menu-link" href="">
              <i class="iconfont icon-kvgl"></i> 我的课程
            </a>
          </li>
        </ul>
      </div>
      <el-menu
          :default-active="$route.path"
          :router="true"
          :unique-opened="true"
          active-text-color="#FA6400"
          background-color="#252830"
          class="el-navbar"
          mode="horizontal"
          text-color="#fff"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">刷题班</template>
          <el-menu-item index="2-1">LeetCode面试刷题班</el-menu-item>
          <el-menu-item index="2-2">北美SDE算法速成班</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">旗舰班</template>
          <el-menu-item index="2-1">课程对比</el-menu-item>
          <el-menu-item index="2-2">北美留学SDE求职班</el-menu-item>
          <el-menu-item index="2-2">北美在职SDE跳槽班</el-menu-item>
        </el-submenu>
        <el-menu-item index="3">旗舰班</el-menu-item>
        <el-menu-item index="4">LC分类顺序表</el-menu-item>
        <el-menu-item index="5">创始人故事</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: #252830;
  height: 50px;
}

.header .container {
  position: relative;
  height: 50px;
}

.header .container .logo {
  float: left;
  font-size: 0;
  padding: 7px 0;
}

.header .container .logo img {
  height: 36px;
}

.el-navbar {
  float: right;
  height: 50px;
  border-bottom: 0;
  background-color: transparent !important;
}

.header /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 50px;
  line-height: 50px;
}

.header .el-menu--horizontal > .el-menu-item {
  height: 50px;
  line-height: 50px;
}

.head-right {
  float: right;
  height: 50px;
}

.head-menu {
  height: 50px;
}

.head-menu li {
  height: 50px;
  float: left;
}

.head-menu .menu-link {
  position: relative;
  display: block;
  line-height: 50px;
  color: #ffffff;
}

.head-menu .menu-link .iconfont {
  font-size: 18px;
}

.head-menu .menu-link .el-badge {
  display: inline-block;
  background-color: #e02020;
  font-size: 12px;
  color: #ffffff;
  line-height: 1;
  padding: 0 8px;
  border-radius: 30px;
  position: absolute;
  left: 50%;
  top: 10px;
}

.head-menu .menu-link .el-dot-badge {
  display: inline-block;
  background-color: #e02020;
  border-radius: 30px;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  margin-left: 5px;
  top: 10px;
}

.head-menu li.line {
  width: 1px;
  height: 16px;
  margin: 18px 15px;
  background-color: #ffffff;
  opacity: 0.5;
}

.head-menu li .btn-free {
  width: 86px;
  height: 24px;
  line-height: 24px;
  color: #ffffff;
  margin: 13px;
  background: #e02020;
  border-radius: 16px;
  display: block;
  border-radius: 30px;
  text-align: center;
}
</style>
