<template>
  <!-- 北美SDE求职速成班 -->
  <div>
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">北美SDE求职速成班</div>
            <div class="sch-desc">
              <!--              Google，Facebook，Amazon，Microsoft等大厂为目标，横扫所有公司面试<br />-->
              Algorithm，OOD，System Design，北美求职一站式解决方案<br />
              简历修改，求职规划，模拟面试，北美内推，助你突破公司算法面试
            </div>
            <ul class="leet-date">
              <li>
                <div class="val">7 <span>人</span></div>
                <div class="txt">VIP小班</div>
              </li>
              <li>
                <div class="val">15 <span>周</span></div>
                <div class="txt">系统学习</div>
              </li>
              <li>
                <div class="val">10<span>+</span></div>
                <div class="txt">北美内推</div>
              </li>
              <li>
                <div class="val">90 <span>天</span></div>
                <div class="txt">1v1电话监督</div>
              </li>
              <li>
                <div class="val">200<span>+</span></div>
                <div class="txt">小时课程</div>
              </li>
              <li>
                <div class="val">1000 <span>道</span></div>
                <div class="txt">LC题目讲解</div>
              </li>
              <!--              <li>-->
              <!--                <div class="val">5000 <span>+</span></div>-->
              <!--                <div class="txt">学员好评</div>-->
              <!--              </li>-->
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
              <img src="../../assets/images/scb/shipin.png" alt="" />
              <div class="v-icon"></div>
              <div class="v-title">课程简介 & 免费试听</div>
            </div>
            <!-- <div class="countDownText" v-if="countDownTimeStamp != -1">
              <span class="text"
                >下一期开课：<span class="countsNum">{{ countDays }}</span
                >天<span class="countsNum">{{ countHours }}</span
                >小时<span class="countsNum">{{ countMinutes }}</span
                >分<span class="countsNum">{{ countSeconds }}</span
                >秒</span
              >
            </div> -->
          </div>
        </div>
        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
              <img src="@/assets/imgs/priceBanner.png" alt="" />
              <div
              	class="price-tip"
                style="
                  color: rgba(0, 0, 0, 0.65);
                  text-align: center;
                  font-size: 12px;
                  position: absolute;
                  right: 82px;
                  top: 15px;
                "
              >
                立即报名，立即开始！报名即学，不浪费一分一秒！
              </div>
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div
                    class="prices"
                    style=""
                    v-if="comboData.length > 0"
                  >
                    <span style="font-size: 28px; color: #de1306">
                      ${{
                        comboData[0].usddiscountPrice != 0
                          ? comboData[0].usddiscountPrice
                          : comboData[0].usdprice
                      }}</span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;/&nbsp;&yen;{{
                        comboData[0].discountPrice != 0
                          ? comboData[0].discountPrice
                          : comboData[0].price
                      }}</span
                    >
                  </div>
                  <div class="pricesTips">
                    <div class="dollar">支持美元付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div>
                </div>
                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>
        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
              />
              <div v-if="stickyToggle" class="price" style="right: 75px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="comboData.length > 0"
                >
                  ${{
                    comboData[0].usddiscountPrice > 0
                      ? comboData[0].usddiscountPrice
                      : comboData[0].usdprice
                  }}</span
                >
                <span style="font-size: 12px; color: #e02020"
                  >/&yen;{{
                    comboData[0].discountPrice > 0
                      ? comboData[0].discountPrice
                      : comboData[0].price
                  }}</span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
          <div class="g-section bg-gray">
            <div class="g-header">
              <h3>北美SDE求职一站式解决方案，以FMAG世界超一流公司为目标</h3>
              <p>春招秋招的不二选择，最短时间拿下面试</p>
            </div>
            <div class="container">
              <div class="tx-cells">
                <el-row :gutter="30">
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip1.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd">
                          <i class="tar tar-1"></i>New Grad
                        </div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          北美留学生，秋招春招求职<br />
                          全职Entry Level<br />
                          最短时间拿下面试的不二选择<br />
                          北美面试最终最优解决方案
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip2.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd">
                          <i class="tar tar-2"></i>在职跳槽
                        </div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          上班没时间？录播课程为主<br />
                          自由安排学习进度<br />
                          LeetCode答案一应俱全<br />
                          节省90%时间，2-3个月完成跳槽
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip3.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd">
                          <i class="tar tar-3"></i>Internship
                        </div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          秋季春季入学<br />
                          马上短期破解面试<br />
                          拿到FMAG面试通过<br />
                          为自己进大厂有个坚实的基础
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 北美求职内容全覆盖，体系化课程短期速成 -->
          <div class="g-section gtx-section">
            <div class="g-header">
              <h3>北美求职内容解析，体系化课程短期速成</h3>
              <p>算法，OOD，System Design三大北美面试核心内容</p>
            </div>
            <div class="container">
              <div class="tx-main">
                <div class="tx-cell tx-step-1">
                  <div class="tx-box tx-box-1">
                    <div>
                      <img
                        src="../../assets/images/beimei/st1.png"
                        alt=""
                      /><span>Step 1</span>
                    </div>
                  </div>
                  <div class="tx-box tx-box-2">
                    <div>算法技巧训练</div>
                  </div>
                  <div class="tx-box tx-box-3">
                    <div>
                      3000题方法总结<br />
                      套路 模版汇总<br />
                      见题瞬间想出思路
                    </div>
                  </div>
                </div>
                <div class="tx-cell tx-step-2">
                  <div class="tx-box tx-box-1">
                    <div>
                      <img
                        src="../../assets/images/beimei/st2.png"
                        alt=""
                      /><span>Step 2</span>
                    </div>
                  </div>
                  <div class="tx-box tx-box-2">
                    <div>LeetCode<br />千题讲解</div>
                  </div>
                  <div class="tx-box tx-box-3">
                    <div>
                      省去找答案理 解答案时间<br />
                      ⼀天10题不是梦<br />
                      全⽹网唯⼀<br />
                      ⼀题多解 最优解
                    </div>
                  </div>
                </div>
                <div class="tx-cell tx-step-3">
                  <div class="tx-box tx-box-1">
                    <div>
                      <img
                        src="../../assets/images/beimei/st3.png"
                        alt=""
                      /><span>Step 3</span>
                    </div>
                  </div>
                  <div class="tx-box tx-box-2">
                    <div>设计模式</div>
                  </div>
                  <div class="tx-box tx-box-3">
                    <div>
                      模式汇总总结<br />
                      Gof23种设计模式<br />
                      模式应用场景<br />
                      每种优缺点
                    </div>
                  </div>
                </div>
                <div class="tx-cell tx-step-4">
                  <div class="tx-box tx-box-1">
                    <div>
                      <img
                        src="../../assets/images/beimei/st4.png"
                        alt=""
                      /><span>Step 4</span>
                    </div>
                  </div>
                  <div class="tx-box tx-box-2">
                    <div>OODesign</div>
                  </div>
                  <div class="tx-box tx-box-3">
                    <div>
                      UML知识铺垫<br />
                      SOLID设计原则<br />
                      技巧思考方式<br />
                      场景案例分析
                    </div>
                  </div>
                </div>
                <div class="tx-cell tx-step-5">
                  <div class="tx-box tx-box-1">
                    <div>
                      <img
                        src="../../assets/images/beimei/st5.png"
                        alt=""
                      /><span>Step 5</span>
                    </div>
                  </div>
                  <div class="tx-box tx-box-2">
                    <div>System Design</div>
                  </div>
                  <div class="tx-box tx-box-3">
                    <div>
                      UML知识铺垫<br />
                      SOLID设计原则<br />
                      技巧思考方式<br />
                      场景案例分析
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 解析北美FMAG级别公司面试内容，针对面试的完美解决方案 -->
          <div class="g-section">
            <div class="g-header">
              <h3>解析北美FMAG级别公司面试内容，针对面试的完美解决方案</h3>
              <p>无论是OA，Phone Interview，Onsite，算法面试内容全部覆盖</p>
            </div>
            <div class="container">
              <div class="fn-cells">
                <div class="fn-cell bg0"></div>
                <div class="fn-cell fn-odd bg1">
                  <div class="icon">
                    <img src="../../assets/images/beimei/jj1.png" alt="" />
                  </div>
                  <div class="tit">Resume</div>
                  <div class="nn1">01</div>
                  <div class="ch-tit">简 历</div>
                  <div class="desc">
                    <span>如何修改简历<br />让你从众人中脱颖而出</span>
                  </div>
                </div>
                <div class="fn-cell fn-even bg2">
                  <div class="ch-tit">交 流</div>
                  <div class="desc">
                    <span>和面试官如何交流<br />更好的表述</span>
                  </div>
                  <div class="nn1">02</div>
                  <div class="tit">Communication</div>
                  <div class="icon">
                    <img src="../../assets/images/beimei/jj2.png" alt="" />
                  </div>
                </div>
                <div class="fn-cell fn-odd bg3">
                  <div class="icon">
                    <img src="../../assets/images/beimei/jj3.png" alt="" />
                  </div>
                  <div class="tit">Algorithm</div>
                  <div class="nn1">03</div>
                  <div class="ch-tit">算 法</div>
                  <div class="desc">
                    <span>面试占比约70%<br />刷题是北美必会技能</span>
                  </div>
                </div>
                <div class="fn-cell fn-even bg4">
                  <div class="ch-tit">面向对象设计</div>
                  <div class="desc">
                    <span>必备的设计能力<br />是否具有全局思维</span>
                  </div>
                  <div class="nn1">04</div>
                  <div class="tit">OOD</div>
                  <div class="icon">
                    <img src="../../assets/images/beimei/jj4.png" alt="" />
                  </div>
                </div>
                <div class="fn-cell fn-odd bg5">
                  <div class="icon">
                    <img src="../../assets/images/beimei/jj5.png" alt="" />
                  </div>
                  <div class="tit">System Design</div>
                  <div class="nn1">05</div>
                  <div class="ch-tit">系统设计</div>
                  <div class="desc">
                    <span>北美特有的考点<br />跳槽必考必会</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 自己学慢的理由，可以节省大量的时间 -->
          <div class="g-section">
            <div class="container">
              <div class="ly-cells">
                <div class="g-header">
                  <h3>自己学慢的理由，可以节省大量的时间</h3>
                  <p>
                    完全可以节省这些机械性工作，让你直达目的，最短速成理解算法
                  </p>
                </div>
                <el-row :gutter="40" style="margin-bottom: 50px">
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/beimei/jq2.png" /><span
                          >难题意</span
                        >
                      </div>
                      <div class="bd">
                        很多题意表述不清造成歧义<br />
                        或者有些情况没有说明<br />
                        需要自己猜
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/beimei/jq3.png" /><span
                          >找答案</span
                        >
                      </div>
                      <div class="bd">
                        网上答案众多<br />
                        不知道是否这个解法是最优解<br />
                        这个题是否需要一题多解<br />
                        哪些解法有意义，哪些解法不用看
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/beimei/jqr.png" /><span
                          >辨优劣</span
                        >
                      </div>
                      <div class="bd">
                        有些解法代码规范一塌糊涂<br />
                        代码累赘重复<br />
                        好的解法一行解决，坏的解法5行<br />
                        初学者很难分辨
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="40" type="flex" justify="center">
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/beimei/jq4.png" /><span
                          >看不懂</span
                        >
                      </div>
                      <div class="bd">
                        很多解法只有代码没有注释<br />
                        理解起来费劲<br />
                        经常研究2-3小时也理解不了<br />
                        浪费了大量的时间
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/beimei/jq1.png" /><span
                          >没人问</span
                        >
                      </div>
                      <div class="bd">
                        很多小伙伴都是孤军奋战<br />
                        有问题没人讨论，只能自己闭门造车<br />
                        有时候高水平的一句话<br />
                        可以让人豁然开朗
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 高效的学习保障：学、练、测、评、答一体，让扎实真正落实 -->
          <div class="g-section">
            <div class="g-header">
              <h3>真实高效的学习保证，学练答疑直播服务一体化</h3>
              <p>真实教学内容，所有的服务和努力，只为我们最终的Offer</p>
            </div>
            <div class="container">
              <div class="bz-card">
                <div class="bz-inner">
                  <div class="bz-tags">
                    <el-button
                      @click="setActiveItem(0)"
                      :class="{ active: bzid === 0 }"
                      >视频学习
                    </el-button>
                    <el-button
                      @click="setActiveItem(1)"
                      :class="{ active: bzid === 1 }"
                      >LC作业
                    </el-button>
                    <el-button
                      @click="setActiveItem(2)"
                      :class="{ active: bzid === 2 }"
                      >LC讲解
                    </el-button>
                    <el-button
                      @click="setActiveItem(3)"
                      :class="{ active: bzid === 3 }"
                      >电话监督
                    </el-button>
                    <el-button
                      @click="setActiveItem(4)"
                      :class="{ active: bzid === 4 }"
                      >微信群答疑
                    </el-button>
                    <el-button
                      @click="setActiveItem(5)"
                      :class="{ active: bzid === 5 }"
                      >学习小测
                    </el-button>
                    <el-button
                      @click="setActiveItem(6)"
                      :class="{ active: bzid === 6 }"
                      >直播解惑
                    </el-button>
                  </div>
                  <div class="bz-body">
                    <el-carousel
                      height="380px"
                      :interval="2000"
                      arrow="always"
                      @change="echange"
                      ref="carousel"
                    >
                      <el-carousel-item name="0">
                        <div class="bz-photo">
                          <img
                            src="../../assets/images/beimei/lb1.png"
                            alt=""
                          />
                        </div>
                      </el-carousel-item>
                      <el-carousel-item name="1">
                        <div class="bz-photo">
                          <img
                            src="../../assets/images/beimei/lb2.png"
                            alt=""
                          />
                        </div>
                      </el-carousel-item>
                      <el-carousel-item name="2">
                        <div class="bz-photo">
                          <img
                            src="../../assets/images/beimei/lb3.png"
                            alt=""
                          />
                        </div>
                      </el-carousel-item>
                      <el-carousel-item name="3">
                        <div class="bz-photo">
                          <img
                            src="../../assets/images/beimei/lb4.png"
                            alt=""
                          />
                        </div>
                      </el-carousel-item>
                      <el-carousel-item name="4">
                        <div class="bz-photo">
                          <img
                            src="../../assets/images/beimei/lb5.png"
                            alt=""
                          />
                        </div>
                      </el-carousel-item>
                      <el-carousel-item name="5">
                        <div class="bz-photo">
                          <img
                            src="../../assets/images/beimei/lb6.png"
                            alt=""
                          />
                        </div>
                      </el-carousel-item>
                      <el-carousel-item name="6">
                        <div class="bz-photo">
                          <img
                            src="../../assets/images/beimei/zhibb.png"
                            alt=""
                          />
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 北美面试流程解析，以面试为最终目的 -->
          <div class="g-section mud-section">
            <div class="g-header">
              <h3>北美面试流程解析，以面试为最终目的</h3>
              <p>面试内容分析，SDE面试流程大解析，不走任何弯路</p>
            </div>
            <div class="container">
              <el-row style="padding: 0 8%">
                <el-col :span="8">
                  <div class="mud-card">
                    <div class="tit">1轮OA——Online Assessment</div>
                    <div class="desc">
                      公司发链接，在线写code的自动判题系统<br />
                      Online Judgement<br />
                      在线提交自动审核，以算法题为主
                    </div>
                    <div class="pic">
                      <img src="../../assets/images/beimei/mud1.png" alt="" />
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="mud-card mud-even">
                    <div class="pic">
                      <img src="../../assets/images/beimei/mud2.png" alt="" />
                    </div>
                    <div class="tit">2轮Phone Interview——电话面试</div>
                    <div class="desc">
                      面试官打电话面试，发链接<br />
                      在Google Doc等平台写代码<br />
                      有的公司会进行Video Interview<br />
                      和面试官面对面交流，以算法题为主
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="mud-card">
                    <div class="tit">4轮onsite——现场面试</div>
                    <div class="desc">
                      公司报销机票到现场面试，4轮为主<br />
                      New Grad以算法题为主<br />
                      个别有OOD的考察，跳槽以2轮算法<br />
                      1轮OOD，1轮System Design为主
                    </div>
                    <div class="pic">
                      <img src="../../assets/images/beimei/mud3.png" alt="" />
                    </div>
                  </div>
                </el-col>
              </el-row>
              <!--              <div class="mud-tip">-->
              <!--                （每家公司可能稍有不同，例如Google电话面试实习可能会有三面）-->
              <!--              </div>-->
            </div>
          </div>
          <!-- 北美面试为主的算法体系，拒绝基础直接以技巧为主 -->
          <div class="g-section">
            <div class="container">
              <div class="poi-main">
                <div class="g-header">
                  <h3>北美面试为主的算法体系，以绝对实力突破公司面试</h3>
                  <p>600题分类顺序，到400题分类顺序，最后总结题型技巧</p>
                </div>
                <div class="jta-cells">
                  <div class="jta-body">
                    <div class="jta-cell jta-a">
                      <div class="j">A</div>
                      <div class="text">
                        题型技巧方法<br />
                        归纳总结
                      </div>
                      <div class="abs-text">
                        <div class="tt">题型技巧</div>
                        <div class="desc">
                          19种题型总结归纳<br />
                          补充面试算法，加入北美FMAG特殊考察题型
                        </div>
                      </div>
                    </div>
                    <div class="jta-cell jta-b">
                      <div class="j">B</div>
                      <div class="text">
                        每周50题<br />
                        LeetCode题目1-400分类顺序
                      </div>
                      <div class="abs-text">
                        <div class="tt">每周50题</div>
                        <div class="desc">
                          2个月突破400题<br />
                          1-400所有题型总结划分为每周任务
                        </div>
                      </div>
                    </div>
                    <div class="jta-cell jta-c">
                      <div class="j">C</div>
                      <div class="text">
                        先易后难<br />
                        LeetCode题目1-600最终分类顺序
                      </div>
                      <div class="abs-text">
                        <div class="tt">先易后难</div>
                        <div class="desc">
                          1-600以题型为基准分类<br />
                          每种题型内题目以先易后难排序，轻松刷题
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="point-cells">
                  <el-row :gutter="30">
                    <el-col :span="4">
                      <div class="point-box point-green">位运算</div>
                      <span class="point-box">栈</span>
                      <span class="point-box">字典树</span>
                    </el-col>
                    <el-col :span="4">
                      <div class="point-box point-green">数学题</div>
                      <span class="point-box">字符串</span>
                      <span class="point-box">线段树</span>
                    </el-col>
                    <el-col :span="4">
                      <div class="point-box point-green">数组题</div>
                      <span class="point-box">图形堆</span>
                      <span class="point-box">树状数组</span>
                    </el-col>
                    <el-col :span="4">
                      <div class="point-box point-green">二分查找</div>
                      <span class="point-box">回溯法</span>
                      <span class="point-box">贪心算法</span>
                    </el-col>
                    <el-col :span="4">
                      <div class="point-box point-green">链表</div>
                      <span class="point-box">图</span>
                      <span class="point-box">设计题</span>
                    </el-col>
                    <el-col :span="4">
                      <div class="point-box point-green">随机</div>
                      <span class="point-box">树</span>
                      <span class="point-box">动态规划</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <UML></UML>
          <OOD></OOD>
          <ObjectOriented></ObjectOriented>
          <UMLDetail></UMLDetail>
          <!-- GoF-23种设计模式的分类表 -->
          <div class="g-section gof-seciotn">
            <div class="container">
              <div class="gof-main">
                <div class="g-header">
                  <h3>
                    <img src="../../assets/images/beimei/ee.png" alt="" /><span
                      >GoF 23种设计模式逐一讲解，打造完整的设计体系框架</span
                    >
                  </h3>
                  <p>设计模式类型划分，在实际场景中应用，如何写代码</p>
                </div>
                <div class="gof-cells">
                  <div class="gof-row">
                    <div class="gof-box gof-box-hd">
                      <div>创建型</div>
                    </div>
                    <div class="gof-box">
                      <div>工厂方法<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>单例模式</div>
                    </div>
                    <div class="gof-box">
                      <div>原型模式</div>
                    </div>
                    <div class="gof-box">
                      <div>抽象工厂<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>建造者<br />模式</div>
                    </div>
                  </div>
                  <div class="gof-row">
                    <div class="gof-box gof-box-hd">
                      <div>结构型</div>
                    </div>
                    <div class="gof-box">
                      <div>(类适配器)<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>代理模式</div>
                    </div>
                    <div class="gof-box">
                      <div>桥接模式</div>
                    </div>
                    <div class="gof-box">
                      <div>装饰模式</div>
                    </div>
                    <div class="gof-box">
                      <div>外观模式</div>
                    </div>
                    <div class="gof-box">
                      <div>享元模式</div>
                    </div>
                    <div class="gof-box">
                      <div>组合模式</div>
                    </div>
                  </div>
                  <div class="gof-row">
                    <div class="gof-box gof-box-hd">
                      <div>行为型</div>
                    </div>
                    <div class="gof-box">
                      <div>模板方法<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>策略模式</div>
                    </div>
                    <div class="gof-box">
                      <div>命令模式</div>
                    </div>
                    <div class="gof-box">
                      <div>职责链<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>状态模式</div>
                    </div>
                    <div class="gof-box has-btm-line">
                      <div>观察者<br />模式</div>
                    </div>
                  </div>
                  <div class="gof-row" style="padding-left: 190px">
                    <div class="gof-box">
                      <div>解释器<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>备忘录<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>访问者<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>迭代器<br />模式</div>
                    </div>
                    <div class="gof-box">
                      <div>中介者<br />模式</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 面试技巧指导如何和面试官互动表现，简历修改指导让你提升竞争力 -->
          <InterviewGuide></InterviewGuide>
          <!-- 专属教学服务，1对1电话监督，认真负责的课程-->
          <TeachService></TeachService>
          <!-- 除了录播课程，我们还有什么服务 -->
          <OtherService></OtherService>
          <!-- 七大速成学习服务，让你事半功倍，早日拿到心仪Offer -->
          <SevenServices></SevenServices>
          <course-description></course-description>
          <offer></offer>
          <free-system></free-system>
          <!-- 价格方案 -->
          <div class="g-section yyh-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>

            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                    class="gg-card"
                    style="margin: 0 auto !important; width: 900px"
                    v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="12">
                        <div class="gg-yh-cell" style="margin: 0 auto">
                          <div :class="['gg-yh-grid yellow']">
                            <div class="gg-price">
                              ${{ comboData[0].usdprice }}
                            </div>
                            <div class="gg-y-price">
                              ¥{{ comboData[0].price }}
                            </div>
                            <!-- <div
                              class="gg-base-price"
                              v-if="comboData[0].usddiscountPrice != 0"
                            >
                              原价：${{ comboData[0].usdprice }}
                            </div> -->
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
                              立即报名，立即开始，前置课程提前学习<br />不浪费一分一秒，报名立刻学习
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >全程以1v1为主，Edward老师带着刷题
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i>1v1简历修改 &
                                1v1模拟面试
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1北美求职规划时间线
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >10+北美FMAG级别公司内推
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >15周时间速成算法和设计
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >班课有效期：6个月
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >课程咨询：添加客服微信号，1v1咨询
                              </li>
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                type="buy"
                                @click="buyCombo"
                                :class="[comboData.length > 1 ? 'red' : '']"
                                >立即购买
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="gg-yh-cell" style="margin: 0 auto">
                          <div :class="['gg-yh-grid red']">
                            <div class="gg-tag-discountTip">
                              <img src="@/assets/imgs/discountTip.png" alt="" />
                            </div>
                            <div class="gg-price">
                              ${{ comboData[0].usddiscountPrice }}
                            </div>
                            <div class="gg-y-price">
                              ¥{{ comboData[0].discountPrice }}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="body-yh">
                              <div class="des">
                                两种课程优惠模式
                                <br />
                                【团购价】2人报名即可团购
                                <br />
                                【早鸟价】前3人报名即可获得
                                <br /><br />
                                扫码添加微信咨询
                              </div>
                              <img
                                class="qrcode"
                                :src="qrcode.imageUrl"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div class="gg-card">
                    <div
                      class="gg-yh-cell gg-jc-cell"
                      @click="Pcselect('1')"
                      :class="pp[0]"
                      v-if="comboData.length > 0"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ${{
                            comboData[0].usddiscountPrice > 0
                              ? comboData[0].usddiscountPrice
                              : comboData[0].usdprice
                          }}
                        </div>
                        <div class="gg-y-price">
                          ¥{{
                            comboData[0].discountPrice > 0
                              ? comboData[0].discountPrice
                              : comboData[0].price
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[0].usddiscountPrice != 0"
                        >
                          原价：${{ comboData[0].usdprice }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[0].discountPrice == 0"
                        >
                          基础版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          速成北美面试，FMAG目标，横扫公司Offer<br />
                          最强刷题体系，OOD，System Design一应俱全
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i>每一周1对1电话监督
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >学习周期：3个月<span style="color: #fa6400"
                              >（+额外2个月复习）</span
                            >
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >每两周考试测验，每月直播讲座
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >微信群交流，助教答疑
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-gj-cell"
                      @click="Pcselect('2')"
                      :class="pp[1]"
                      v-if="comboData.length > 1"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ${{
                            comboData[1].usddiscountPrice > 0
                              ? comboData[1].usddiscountPrice
                              : comboData[1].usdprice
                          }}
                        </div>
                        <div class="gg-y-price">
                          ¥{{
                            comboData[1].discountPrice > 0
                              ? comboData[1].discountPrice
                              : comboData[1].price
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[1].usddiscountPrice != 0"
                        >
                          原价：${{ comboData[1].usdprice }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[1].discountPrice == 0"
                        >
                          高级版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          题型技巧起步，速成北美面试，FMAG目标<br />
                          最强刷题体系，OOD，System Design一应俱全
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i>每一周1对1电话监督
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >学习周期：3个月<span style="color: #fa6400"
                              >（+额外3个月复习）</span
                            >
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >每两周考试测验，每月直播讲座
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >微信群交流，助教答疑
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-qj-cell"
                      @click="Pcselect('3')"
                      :class="pp[2]"
                      v-if="comboData.length > 2"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ${{
                            comboData[2].usddiscountPrice > 0
                              ? comboData[2].usddiscountPrice
                              : comboData[2].usdprice
                          }}
                        </div>
                        <div class="gg-y-price">
                          ¥{{
                            comboData[2].discountPrice > 0
                              ? comboData[2].discountPrice
                              : comboData[2].price
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[2].usddiscountPrice != 0"
                        >
                          原价：${{ comboData[2].usdprice }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[2].discountPrice == 0"
                        >
                          旗舰版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          题型技巧起步，速成北美面试，FMAG目标<br />
                          最强刷题体系，OOD，System Design一应俱全
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i>每一周1对1电话监督
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >学习周期：3个月<span style="color: #fa6400"
                              >（+额外4个月复习）</span
                            >
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >每两周考试测验，每月直播讲座
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >微信群交流，助教答疑
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="gaoduan-yh-popover">
                    <div class="sk-cells">
                      <div class="sk-heading">
                        <div style="width: 20%">LC题目数量</div>
                        <div style="width: 15%">有效期</div>
                        <div style="width: 10%">答疑</div>
                        <div style="width: 15%">阶段测试</div>
                        <div style="width: 15%">电话监督</div>
                        <div style="width: 15%">价格USD</div>
                        <div style="width: 15%">选择</div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('1')"
                        :class="{ active: Pselect === '1' }"
                        v-if="comboData.length > 0"
                      >
                        <div>1-400题</div>
                        <div>{{ comboData[0].qaDuration / 30 }}个月</div>
                        <div>{{ comboData[0].qaDuration / 30 }}个月</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[0].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[0].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[0].usddiscountPrice > 0
                              ? comboData[0].usddiscountPrice
                              : comboData[0].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="1"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('2')"
                        :class="{ active: Pselect === '2' }"
                        v-if="comboData.length > 1"
                      >
                        <div>1-600题</div>
                        <div>{{ comboData[1].qaDuration / 30 }}个月</div>
                        <div>{{ comboData[1].qaDuration / 30 }}个月</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[1].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[1].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[1].usddiscountPrice > 0
                              ? comboData[1].usddiscountPrice
                              : comboData[1].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="2"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('3')"
                        :class="{ active: Pselect === '3' }"
                        v-if="comboData.length > 2"
                      >
                        <div>1-1000题</div>
                        <div>{{ comboData[2].qaDuration / 30 }}个月</div>
                        <div>{{ comboData[2].qaDuration / 30 }}个月</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[2].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[2].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[2].usddiscountPrice > 0
                              ? comboData[2].usddiscountPrice
                              : comboData[2].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="3"></el-radio>
                        </div>
                      </div>
                    </div>
                    <div class="sk-tips">
                      <span class="text-warin"
                        >*点击选中套餐，可立即下单订购</span
                      >
                      <span class="tag">支持花呗分期</span>
                      <span class="tag">支持美元分期付款</span>
                    </div>
                    <div class="skill-yh-foot">
                      <el-button plain type="warning" @click="buyCombo"
                        >立即购买
                      </el-button>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <!-- 相关课程推荐 -->
          <div class="g-section">
            <div class="g-header">
              <h3>—— 相关课程推荐 ——</h3>
            </div>
            <div class="container">
              <div class="rel-main" style="padding-bottom: 0">
                <el-row :gutter="30">
                  <el-col
                    v-for="(item, index) in recommendClasses"
                    :key="index"
                    :span="12"
                  >
                    <div
                      class="rel-card hoverUp"
                      @click="changePage(item.courseClassId)"
                    >
                      <div class="rel-pic">
                        <img :src="item.coverUrl" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-desc">
                          {{ item.brief }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="6"></presales-consultation>
        </div>
        <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />
      </div>
    </div>
    <!-- <div :class="txShow ? 'show' : 'hidex'" class="tx-bar">
      <div class="tx-head-bar" @click="txShow = true">
        <img alt="" src="../../assets/images/scb/bq-logo.png" /><span
          >正式开班时间</span
        >
      </div>
      <div class="tx-bar-bd">
        <el-radio-group v-model="tradio">
          <el-radio :label="1" class="tx-radio" disabled>
            <div class="time">2021年01月11日</div>
            <div class="cont"><span>第22期</span><span>已开班</span></div>
          </el-radio>
          <el-radio :label="2" class="tx-radio" disabled>
            <div class="time">2021年01月15日</div>
            <div class="cont"><span>第23期</span><span>已开班</span></div>
          </el-radio>
          <el-radio :label="3" class="tx-radio">
            <div class="time">2021年02月1日</div>
            <div class="cont"><span>第24期</span><span>开放报名</span></div>
          </el-radio>
          <div class="tx-radio">
            <router-link to="/1">立即报名、立即开始</router-link>
          </div>
        </el-radio-group>
        <div class="tx-close" @click="txShow = false">
          <i class="el-icon-arrow-up"></i>
        </div>
      </div>
    </div> -->

    <poly-video
      v-if="videoShow"
      :br="br"
      @closeVideo="videoShow = false"
    ></poly-video>
    <!-- 提问 -->
    <leetDialog></leetDialog>
    <!-- <div class="tw-bar">
      <div class="bar-v">
        <span class="iconfont icon-houtou"></span><span>讲师咨询</span>
      </div>
      <div class="tw-wx">
        <div class="tw-body">
          <img alt="" src="../../assets/images/scb/wx.png"/>
          <div class="t">扫描微信二维码<br/>课程和网站疑问</div>
          <div class="g">针对课程有疑问？<br/>购买时遇到问题？</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import { addShoppingCart } from "@/service";
import Foot from "@/components/header/Foot";
import scHead from "@/components/header/scHead";
import courseMix from "@/utils/courseMix";
import Menus from "@/components/course/menu";
import CourseDetail from "@/components/course/courseDetail";
import courseDatas from "@/utils/courseDatas";
import freeSystem from "./components/freeSystem";
import offer from "./components/offer";
import presalesConsultation from "./components/presalesConsultation";
import polyVideo from "./components/polyVideo";
import courseDescription from "./components/courseDescription";
import pageSelect from "./components/pageSelect";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help.vue";
import { getCountDownClass } from "@/service/index";
import UML from "@/components/course/coursesComponents/UML";
import OOD from "@/components/course/coursesComponents/OOD";
import ObjectOriented from "@/components/course/coursesComponents/ObjectOriented";
import UMLDetail from "@/components/course/coursesComponents/UMLDetail";
import InterviewGuide from "./components/interviewGuide";
import TeachService from "./components/teachService";
import OtherService from "./components/otherService";
import SevenServices from "./components/sevenServices";
import { getCourseBanner } from "@/service";
export default {
  components: {
    Foot,
    scHead,
    Menus,
    CourseDetail,
    freeSystem,
    offer,
    polyVideo,
    presalesConsultation,
    leetDialog,
    pageSelect,
    pageStickySelect,
    helpClass,
    courseDescription,
    UML,
    OOD,
    ObjectOriented,
    UMLDetail,
    InterviewGuide,
    TeachService,
    OtherService,
    SevenServices,
  },

  mixins: [courseMix, courseDatas],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      bantxt: "北美SDE求职速成班",
      videoShow: false,
      dr: 6,
      br: 6,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/beimei/banner.jpg"),
      form: {
        region: "v1",
      },
      pp: ["ac1", "ac2", "ac3"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        { name: "班课大纲", index: "1", tag: "course_content" },
        // { name: "班课模式", index: "2", tag: "course_mode" },
        // { name: "用户评价", index: "2", tag: "course_comment" },
        { name: "售前咨询", index: "2", tag: "course_question" },
      ],
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
      countDownText: "",
      countDownTimeStamp: -1,
      countDays: 0,
      countHours: 0,
      countMinutes: 0,
      countSeconds: 0,
      qrcode: {},
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCountDownClass();
    getCourseBanner(2).then((res) => {
      if (res.success) {
        this.qrcode = res.result[0];
      }
    });
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    Pcselect(n) {
      this.Pselect = n;
      console.log(n);
      if (n == "1") {
        this.pp = ["ac1", "ac2", "ac3"];
      } else if (n == "2") {
        this.pp = ["ac3", "ac1", "ac2"];
      } else {
        this.pp = ["ac2", "ac3", "ac1"];
      }
      this.selectCombo = this.comboData[parseInt(n) - 1];
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    echange(val) {
      this.bzid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    /**
     * 加入购物车
     */
    addCart() {
      // console.log("----------");
      addShoppingCart([{ objectId: 0 }])
        .then((res) => {
          console.log("success", res);
          this.$message.success("添加购物车成功");
        })
        .catch((err) => {
          console.log("fail", err);
          this.$message.success("添加购物车失败，请重试");
        });
    },
    TabActiveIndexChange(index) {
      this.TabActiveIndex = index;
      console.log("------", this.TabActiveIndex);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    timeCountDown(endDateStr) {
      if (endDateStr == -1) {
        return;
      }
      //结束时间
      var endDate = new Date(endDateStr);
      //当前时间
      var nowDate = new Date();
      //相差的总秒数
      if (endDate < nowDate) {
        window.location.reload();
      }
      var totalSeconds = parseInt((endDate - nowDate) / 1000);
      //天数
      var days = Math.floor(totalSeconds / (60 * 60 * 24));

      //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24);

      //小时数
      var hours = Math.floor(modulo / (60 * 60));

      modulo = modulo % (60 * 60);
      //分钟
      var minutes = Math.floor(modulo / 60);
      //秒

      var seconds = modulo % 60;

      this.countDays = days;
      this.countHours = hours;
      this.countMinutes = minutes;
      this.countSeconds = seconds >= 10 ? seconds : `0${seconds}`;

      setTimeout(() => {
        this.timeCountDown(endDateStr);
      }, 1000);
    },
    getCountDownClass() {
      getCountDownClass(6).then((res) => {
        if (res.success) {
          this.countDownTimeStamp = res.result;
          this.timeCountDown(this.countDownTimeStamp);
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/6-UsCrashCourse.css");
</style>
